import React, { useState } from "react";
import Navbar from "./Navbar";
import { useEffect } from "react";
import Footer from "../Footer";
import { BallTriangle } from "react-loader-spinner";

function Plans() {
  const [loading,setLodaing ]=useState(false)
  const [plansData, setPlansData ]=useState([])



  useEffect(() => {
    plansDatafetch()
}, [])

const plansDatafetch = async()=>{
  setLodaing(true)
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("https://api.jaishreeramhealthcareprivateltd.com/plans/view/all/plans", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.success==true){
          setPlansData(result.data)
        }
      }).finally(()=>{
        setLodaing(false)
      })
  } catch (error) {
    console.log(error)
  }
}

  return <div>
    <section>
      <Navbar />
    </section>
    <section>
      <div className="container pt-2 ">
        <h3 className="text-center text-black">Choose your Plan</h3>
        <div className="row justify-content-center align-items-center">

        {
          loading?  <BallTriangle
          height={50}
          width={50}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          visible={true}
      />:

          plansData.map((res)=>{
            return(
              <div className="col-lg-4 col-sm-12 col-md-4 col-12 mt-4">
              <div className="p-2 pt-3 pb-3" style={{
                border: "1px solid #FF0596", borderRadius: 12,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  
              }}>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>{res.name}</h5>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>{res.nameDes}</p>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>₹ :{res.mrp}</h5>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>{res.mrpDes}</h6>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px", }}>{res.banner}</h6>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}> {res.bannerDes}</p>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px", }}>{res.video}</h6>
                <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>{res.videoDes}</p>
                <button
                style={{
                  border: "1px solid #FF0596",
                  borderRadius: "12px",
                  fontSize: "30px",
                  fontWeight: "700",
                  fontFamily: "Lato",
                  width: "100%",
                  background:
                    res.button === "Subscribe"
                      ? "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                      : "none",
                  color: res.button === "Subscribe" ? "#FFFFFF" : "#000000",
                }}
              >
                {res.button}
              </button>
              </div>
            </div>
            )
          })
        }
          
        </div>
      </div>
    </section>
 <section className="mt-5 ">
 <Footer/>
 </section>
  </div>;
}

export default Plans;
