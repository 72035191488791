import React, { useState } from 'react';
import './Loginscrren.css';
import jai from '../Compontes/Img/jai.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const login = async () => {
    if (!email) {
      toast.error("Email is required", { autoClose: 3000 });
      return;
    }
  
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address", { autoClose: 3000 });
      return;
    }
  
    setLoading(true); 
  
    try {
      const myHeaders = new Headers();


      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({ "user_mail": email });
  
      const requestOptions = {
        method: "POST", 
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
  
      const response = await fetch("https://api.jaishreeramhealthcareprivateltd.com/users/login", requestOptions);
      const result = await response.json(); 
  
      if (result.message) { 
        toast.success(result.message, { autoClose: 3000 });
        navigate("/LoginOtp", { state: { email :email} });
        setEmail(""); 
      } else {
        toast.error(result.message , { autoClose: 3000 });
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred: " + error.message, { autoClose: 3000 }); 
    } finally {
      setLoading(false); 
    }
  };
  
 

  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div className="login-container">
        <div className="login-left-pane">
          <div className="login-logo">
            <div className="login-logo1">
              <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            </div>
          </div>
          <h1 className="pt-3">Welcome<br />Jai Shree Ram Healthcare<br />Pvt. Ltd.!</h1>
          <p className="login-description pt-3">
            Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.
          </p>
        </div>
        <div className="login-right-pane">
          <div className="login-form-container">
            <h3 style={{ textAlign: "center", paddingTop: "100px", fontSize: "32px", color: "#FF1493" }}>Login</h3>
            <div style={{ paddingTop: "50px" }}>
              <h2>Enter your email</h2>
              <div className="login-input-container">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.replace(/\s/g, ""));
                  }}
                  className="login-input"
                />
              </div>
              <div style={{ padding: "19px", paddingTop: "10px", alignContent: "center", justifyContent: "center", display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#FF1493",
                    width: "100%",
                    padding: "10px",
                    color: "white",
                    fontSize: "17px",
                    border: "none",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={login}
                >
                  {loading ? (
                    <BallTriangle
                      height={50}
                      width={50}
                      radius={5}
                      color="#4fa94d"
                      ariaLabel="ball-triangle-loading"
                      visible={true}
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
