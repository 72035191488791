import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import baner from '../Img/baner.png';
import pro from '../Img/pro.png';
import malthora from '../Img/malthora.png';
import './home.css';
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from 'react-icons/fa';
import video from '../Img/video.mp4'
import video1 from '../Img/jsrv2.mp4'
import { BallTriangle } from "react-loader-spinner";
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import locations from '../Img/locations.png'
import { param } from "jquery";
import { toast, ToastContainer } from "react-toastify";
function Home() {
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])
    const [city, setCity] = useState([])
    const [cityid, setnameCityId] = useState("")
    const [selectedCity, setSelectedCity] = useState({ id: "", name: "" })
    console.log(selectedCity, "kakak")
    const [upload, setUploadData] = useState({
        spotlight: [],
        topPlans: { large: [], small: [], mid: [], spot: [] },
        bestOffers: { large: [], small: [], mid: [], spot: [] },
    });

    console.log(upload.topPlans.large)
   const[Banners,setBanners]=useState([])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
    };

    const firstslide = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };



    const navigate = useNavigate()
    useEffect(() => {
        category()
        getCity()
        HomeBnaner()
    }, [])

    const category = async () => {
        setloading(true)
        try {
            const myHeaders = new Headers();
            const token =  localStorage.getItem("token");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch("https://api.jaishreeramhealthcareprivateltd.com/category/categories/Home", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setData(result.data.homeCategories)
                    console.log(result.data.homeCategories, "kajal")
                })
        } catch (error) {
            console.log(error)
        }
    }

   

    const handleNavigation = () => {
        if (selectedCity.id) {
            console.log(`Navigating to Hospitals with ID: ${selectedCity.id} and Name: ${selectedCity.name}`);
            navigate(`/Hospitlas`, { state: { selectedCityId: selectedCity.id, selectedCityName: selectedCity.name } }); 
        } else {
            console.log("City ID is not selected");
            // Show toast notification if no city is selected
            toast.error("Please select a city before proceeding!", { autoClose: 2000 });
        }
    };
    
    


const HomeBnaner = async()=>{
    setloading(true)
try {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token')
    myHeaders.append("Authorization", `Bearer ${token}`);
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    
    fetch("https://api.jaishreeramhealthcareprivateltd.com/banner/getBanners", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBanners(result.data)
      }).finally(()=>{
        setloading(false)
      })
} catch (error) {
    console.log(error)
}
}

    const settingss = {
        items: 2,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {
                items: 2,
            },
        },
    };
    const settingssBest = {
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {
                items: 1,
            },
        },
    };

    const settingsss = {
        items: 4,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {
                items: 4,
            },
        },
    };

    const getCity = async () => {
        setloading(true);
        try {
            const myHeaders = new Headers();
            const token = localStorage.getItem("token");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                "https://api.jaishreeramhealthcareprivateltd.com/cityRoute/cities",
                requestOptions
            );
            const result = await response.json();
            setCity(result.cities);
            setnameCityId(result.cities._id)
            console.log(result.cities._id, "kkkk")
        } catch (error) {
            console.log(error);
        } finally {
            setloading(false);
        }
    };



    return (
        
        <div style={{ overflowX: "hidden" }}>
        <div>
        <ToastContainer/>
        </div>
       
        <div style={{ backgroundColor: "pink", display: "flex", alignItems: "center", gap: "40px", padding: "10px" }}>
        <img src={locations} style={{ height: 20, width: 20, marginLeft: "10px" , }} />
        <select
        className="form-select"
        aria-label="Default select example"
        style={{ width: "20%", border: "none", listStyle: "none" }}
        onChange={(e) => {
          const selectedIndex = e.target.selectedIndex;
          const selectedCityName = e.target[selectedIndex].text;
          const selectedCityId = e.target.value;
          
          // Set both city ID and city name in state
          setSelectedCity({ id: selectedCityId, name: selectedCityName });
        }}
      >
        <option value="" selected>Pls select City</option>
        {city.map((cityItem, index) => (
          <option key={index} value={cityItem._id}>
            {cityItem.name}
          </option>
        ))}
      </select>
      
      </div>
      <section className="navbar-section mb-0" style={{zIndex:1000}}>
        <Navbar />
      </section>

            <section style={{marginBottom:30}}>
                <div className="container-fluid pt-4">
                    <div className="slider-container">
                        <Slider {...firstslide}>
                            <div className="slider-item">
                                <img src={baner} style={{ objectFit: "fill" }} className="slider-img" />
                            </div>

                            <div className="slider-item">
                                <img src={malthora} style={{ objectFit: "fill" }} className="slider-img" />
                            </div>
                            <div className="slider-item">
                                <img src={baner} style={{ objectFit: "fill" }} className="slider-img" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            
            <section>
                <div className="container">
                    <div className="row pt-5 expolore">
                        <div className="  top pe-5 d-flex justify-content-between ">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Explore Categories</h3>
                            <p onClick={() => navigate("/CategeroyCards")} style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600", textDecorationLine: "underline" }}>Sell All</p>
                        </div>
                        {
                            loading
                                ?
                                <BallTriangle
                                    height={50}
                                    width={50}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    visible={true}
                                /> :
                                data?.map((res) => (
                                    <button
                                        style={{ border: "none", background: "none" }}
                                        onClick={handleNavigation}
                                        className="col-lg-2 col-md-6 col-sm-6 col-6 mb-4"
                                    >
                                        <div style={{
                                            backgroundColor: "#FF1493",
                                            borderRadius: "10px",
                                            overflow: "hidden"
                                        }}>

                                            <div style={{ height: "130px", width: "100%" }}>
                                                <img
                                                    src={res.category_image}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: "#FF1493",

                                                }}
                                            >
                                                <p className="fs-5 text-white p-2" style={{ textAlign: "center" }}>
                                                    {res.category_name}
                                                </p>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                    </div>
                </div>
            </section>
 {/*     {/*In Spot Light */}
 <section className="pt-5 explore">
 <div className="top spot ps-5 pe-5">
     <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
         In Spotlight
     </h3>
 </div>
 <div className="container-fluid ex p-5" style={{ backgroundColor: "#FF1496" }}>
     <div className="slider-container">
         {loading ? (
             <BallTriangle
                 height={50}
                 width={50}
                 radius={5}
                 color="#4fa94d"
                 ariaLabel="ball-triangle-loading"
                 visible={true}
             />
         ) : (
             <Slider {...firstslide}>
                 {Banners.map((res) => {
                     // console.log(res.banner,"kdjsdshf");
                     return (
                         <div key={res.id} className="col-lg-12 col-5">
                             <div
                                 className="data"
                                 style={{
                                     backgroundSize: "cover",
                                     overflow: "hidden",
                                     height: 400,
                                     borderRadius: 10,
                                     backgroundRepeat: "no-repeat",
                                     width: "100%",
                                     border: "2px solid pink",
                                 }}
                             >
                                 <img
                                     src={`${res.spotlightBanners}`}
                                     alt={res.name}
                                     style={{
                                         height: "100%",
                                         width: "100%",
                                         objectFit: "cover",
                                         borderRadius: 10,
                                         overflow: "hidden",
                                     }}
                                 />
                             </div>
                         </div>
                     )
                 })}
             </Slider>
         )}
     </div>
 </div>
</section>
    
            <section>
            {/* Top Plans */}
            <div className="top pt-5 spot ps-5">
                <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Top Plans</h3>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <TinySlider settings={settingss}>
                        {Banners.filter(banner => banner.topPlansBanners && banner.size === '2:1').map((slide, index) => (
                            <div
                                key={index}
                                className="col-md-6 col-sm-12 p-2 col-12 topplas"
                                style={{
                                    height: 300,
                                    backgroundSize: "cover",
                                    overflow: "hidden",
                                }}
                            >
                                <img
                                    src={slide.topPlansBanners}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: 10,
                                        border: "2px solid #FF0596"
                                    }}
                                />
                            </div>
                        ))}
                    </TinySlider>
                </div>
            </div>
        </section>
        
        {/* Top Plans Spot */}
        <section>
            <div className="container-fluid">
                <div className="row best pt-5 pb-3">
                    <div className="slider-container">
                        <TinySlider settings={settingsss}>
                            {Banners.filter(banner => banner.topPlansBanners && banner.size === '1:1').map((slide, index) => (
                                <div key={index} className="col-lg-5 col-sm-12 col-md-6 col-5 topplas"
                                     style={{
                                        height: 200,
                                        backgroundSize: "cover",
                                        overflow: "hidden",
                                        padding:"10px"
                                    }}
                                >
                                    <img
                                        src={slide.topPlansBanners}
                                        style={{ height: "100%", width: "100%", objectFit: "cover",
                                        borderRadius: 5,
                                        border: "2px solid #FF1493",

                                         }}
                                    />
                                </div>
                            ))}
                        </TinySlider>
                    </div>
                </div>
            </div>
        </section>
        
        {/* Top Plans Mid */}
        <section>
            <div className="container-fluid">
                <div className="row best">
                    <div className="slider-container">
                        <TinySlider settings={settingsss}>
                            {Banners.filter(banner => banner.topPlansBanners && banner.size === '3:4').map((slide, index) => (
                                <div key={index} className="card1">
                                    <div className="topplas"
                                        style={{
                                            height: 270,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            borderRadius: 10,
                                            border: "2px solid pink"
                                        }}
                                    >
                                        <img
                                            src={slide.topPlansBanners}
                                            style={{
                                                width: "100%",
                                                objectFit: "cover",
                                                height: "100%"
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </TinySlider>
                    </div>
                </div>
            </div>
        </section>
        
        {/* Top Plans Small */}
        <section>
            <div className="container-fluid">
                <div className="row best pt-5 pb-3">
                    <div className="slider-container">
                        <TinySlider settings={settingsss}>
                            {Banners.filter(banner => banner.topPlansBanners && banner.size === '4:1').map((slide, index) => (
                                <div key={index} className="col-lg-5 col-5">
                                    <div className="data"
                                        style={{
                                            backgroundSize: "cover",
                                            height: 400,
                                            overflow: "hidden",
                                            borderRadius: 10,
                                            backgroundRepeat: "no-repeat",
                                            width: "100%",
                                            border: "2px solid pink"
                                        }}
                                    >
                                        <img
                                            src={slide.topPlansBanners}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: 10,
                                                overflow: "hidden"
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </TinySlider>
                    </div>
                </div>
            </div>
        </section>
        

            {/*Our Services1*/}
            <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center">
                        {/* 50% for Heading and Description */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSize: "36px", fontWeight: "bold" }}>Our Services</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>


        {/* Best Offers */}
<section>
<div className="container-fluid topimg ps-4 pe-4">
    <div className="row">
        <div className="top spot ps-5">
            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
                Best Offers
            </h3>
        </div>
        <div className="col-lg-12 pt-4 col-sm-12 col-md-12">
            <div className="slider-container">
                <TinySlider settings={settingssBest}>
                    {Banners.filter((slide) => slide.size === "2:1").map((slide, index) => (
                        <div key={index} className="col-lg-12 col-5">
                            <div className="data" style={{
                                backgroundSize: "cover",
                                height: 400,
                                overflow: "hidden",
                                borderRadius: 10,
                                backgroundRepeat: "no-repeat",
                                width: "100%",
                                border: "2px solid pink"
                            }}>
                                <img src={slide.bestOffersBanners}
                                     style={{
                                         height: "100%",
                                         width: "100%",
                                         objectFit: "cover",
                                         borderRadius: 10,
                                         overflow: "hidden"
                                     }} />
                            </div>
                        </div>
                    ))}
                </TinySlider>
            </div>
        </div>
    </div>
</div>
</section>


            <section className="pt-5">
                <div className="container-fluid  topimg pt-5 pb-5 p-3">
                    <img src={pro} style={{ height: "100%", width: "100%" }} />
                </div>
            </section>

            <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center">
                        {/* 50% for Heading and Description */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSizez: "36px", fontWeight: "bold" }}>Our Services</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.
                            </p>
                        </div>

                        {/* 50% for Video */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

         {/* Best Offers Small */}
<section>
<div className="container-fluid">
    <div className="row best p-3">
        <TinySlider settings={settingsss}>
            {Banners.filter((slide) => slide.size === "4:1").map((slide, index) => (
                <div key={index} className="topplas col-lg-5 col-sm-12 col-md-6 col-5 "
                     style={{
                         height: 270,
                         backgroundSize: "cover",
                         overflow: "hidden",
                         padding:"10px"
                     }}>
                    <img src={slide.bestOffersBanners}
                         style={{
                             height: "100%",
                             width: "100%",
                         border: "2px solid #FF1493",
                         borderRadius: 5,
                             objectFit: "cover"
                         }} />
                </div>
            ))}
        </TinySlider>
    </div>
</div>
</section>


           {/* Best Offers Spot */}
<section>
<div className="container-fluid">
    <div className="row best p-3">
        <TinySlider settings={settingsss}>
            {Banners.filter((slide) => slide.size === "1:1").map((slide, index) => (
                <div key={index} className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                     style={{
                         height: 200,
                         backgroundSize: "cover",
                         overflow: "hidden",
                         padding:"10px"
                     }}>
                    <img src={slide.bestOffersBanners}
                         style={{
                             height: "100%",
                             width: "100%",
                             objectFit: "cover",
                         borderRadius: 5,
                         border: "2px solid #FF1493",

                         }} />
                </div>
            ))}
        </TinySlider>
    </div>
</div>
</section>


          {/* Best Offers Mid */}
<section>
<div className="container-fluid">
    <div className="row best p-3">
        <TinySlider settings={settingsss}>
            {Banners.filter((slide) => slide.size === "3:4").map((slide, index) => (
                <div key={index} className="topplas col-lg-5 col-sm-12 col-md-6 col-5 "
                     style={{
                         height: 220,
                         backgroundSize: "cover",
                         overflow: "hidden",
                         padding:"10px"

                     }}>
                    <img src={slide.bestOffersBanners}
                         style={{
                             height: "100%",
                             width: "100%",
                             objectFit: "cover",
                             border: "2px solid #FF1493",
                         borderRadius: 5,

                         }} />
                </div>
            ))}
        </TinySlider>
    </div>
</div>
</section>



            <a
                href="https://wa.me/9653778555"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#25D366',
                    color: 'white',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}
            >
                <FaWhatsapp size={30} />
            </a>
            {/* Footer Section */}
            <section className="mt-5">
                <Footer />
            </section>
        </div>
    );
}

export default Home;