import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser, FaTransgender, FaGem, FaLocationArrow, FaHandHoldingMedical, FaBriefcaseMedical } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import loinback from "../Compontes/Img/loginBack.png";
import './login.css';

const SignUpForm = () => {
  const [name, setName] = useState("");
  const [username, setuserName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [profession, setProfession] = useState("");
  const [number, setNumber] = useState("");
  const [insurance, setInsurance] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Basic validation for required fields
    if (
      !name ||
      !username ||
      !email ||
      !gender ||
      !age ||
      !address ||
      !bloodGroup ||
      !profession ||
      !number
    ) {
      toast.error("Please fill in all required fields", {
        autoClose: 3000,
      });
      return;
    }

    // Validate phone number length
    if (number.length !== 10) {
      toast.error("Contact number must be 10 digits long", {
        autoClose: 3000,
      });
      return;
    }

    // Regex to prevent spaces in the inputs
    const regex = /^\S*$/;

    if (!regex.test(name) || !regex.test(username) || !regex.test(email)) {
      toast.error("Fields cannot contain spaces", {
        autoClose: 3000,
      });
      return;
    }

    signUpUser();
  };

  const signUpUser = async () => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "username": username,
        "email": email,
        "fullName":name,
        "mobile": number,
        "gender": gender,
        "age":age,
        "address": address,
        "bloodGroup": bloodGroup,
        "profession": profession,
        "hasInsurance": insurance,
        "insuranceType": insuranceType,
        "insuranceCompany": insuranceCompany
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.jaishreeramhealthcareprivateltd.com/users/signup", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success===true) {
        toast.success(result.message, {
          autoClose: 3000,
        });
        navigate("/home");

      } else {
        toast.error(result.message , {
          autoClose: 3000,
        });
      }
    })
    } catch (error) {
      console.error("Signup error:", error);
      toast.error("An error occurred. Please try again.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };


  

  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div
        className="container-fulid imgsee"
        style={{
          backgroundImage: `url(${loinback})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          alignItems: "center",
        }}
      >
        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", justifyContent: "center", alignItems: "center", display: "flex" }}>
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setuserName(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-container">
              <IoCall size={24} />
              <input
                className="input"
                type="number"
                placeholder="Number"
                value={number}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setNumber(e.target.value);
                  }
                }}
              />
            </div>
            <div className="input-container">
              <FaTransgender size={24} />
              <input
                className="input"
                type="text"
                placeholder="Gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaGem size={24} />
              <input
                className="input"
                type="number"
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaLocationArrow size={24} />
              <input
                className="input"
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Do you have Insurance?"
                value={insurance}
                onChange={(e) => setInsurance(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Insurance Type"
                value={insuranceType}
                onChange={(e) => setInsuranceType(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Insurance Company"
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
              />
            </div>
            <label className="label">Blood Group</label>
            <div className="blood-group-container">
              {['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'].map((group) => (
                <button
                  key={group}
                  type="button"
                  className={`blood-group-button ${bloodGroup === group ? 'selected' : ''}`}
                  onClick={() => setBloodGroup(group)}
                >
                  {group}
                </button>
              ))}
            </div>
            <div className="input-container">
              <FaBriefcaseMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Profession"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>
            <button type="submit" className="button" disabled={loading} style={{ backgroundColor: "#0539FF", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {loading ? "Signing Up..." : "Sign Up"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
