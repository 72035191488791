import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import { BallTriangle } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";

export default function Hospitals() {
  const location = useLocation();
  const { selectedCityId, selectedCityName } = location.state || {}; 
  console.log(selectedCityId, selectedCityName ,"kajal")
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchSubCategory();
  }, [page]); // Fetch data whenever the page changes

  const fetchSubCategory = async () => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${token} `);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.jaishreeramhealthcareprivateltd.com/category/subCategories/MainScreen?categoryID=${selectedCityId}&page=${page}&limit=10&city=${selectedCityName}`,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        setData(result.data.items || []); // Assuming your API returns an array of items
        setCategory(result.data.category || ""); // Adjust based on your API structure
        setTotalPages(result.data.totalPages || 0); // Update totalPages based on your API response
      } else {
        setData([]); // Set to empty array if API indicates failure
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <section>
        <Navbar />
      </section>

      <section>
        <div className="container-fluid">
          {/* Heading Section */}
          <div className="text-center pt-5 pb-3">
            <h1>{category}</h1>
          </div>
          <div className="row">
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <BallTriangle
                  height={50}
                  width={50}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  visible={true}
                />
              </div>
            ) : data.length > 0 ? (
              data.map((res) => (
                <div key={res.id} className="col-lg-4 col-sm-12 col-md-12 mt-4 mb-5">
                  <div
                    style={{
                      height: "180px",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <img
                      src={res.url}
                      alt={res.name}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#faf5f5",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      borderBottomRightRadius: "10px",
                      borderEndStartRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <h4>{res.name}</h4>
                    <h6>Address:</h6>
                    <p>{res.address}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt-5">
                <h5>No data found</h5>
              </div>
            )}
          </div>
        </div>
      </section>
<div style={{display:"flex",justifyContent:"center"}}>
<Pagination 
        count={totalPages} 
        page={page} 
        onChange={handlePageChange} 
        variant="outlined" 
        shape="rounded"
        color="primary"
      />
</div>
      

      <section className="mt-5">
        <Footer />
      </section>
    </div>
  );
}
