  import React, { useState, useEffect } from 'react';
  import './Loginscrren.css';
  import jai from '../Compontes/Img/jai.png';
  import OtpInput from 'react-otp-input';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { toast, ToastContainer } from 'react-toastify';
  import { BallTriangle } from 'react-loader-spinner';

  const LoginOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email ,id} = location.state || {};
    console.log(email)
    const [otp, setOtp] = useState();
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(60); 
    const [canResend, setCanResend] = useState(false);
    // console.log(contact,"kajal")
    useEffect(() => {
      if (counter > 0) {
        const timer = setInterval(() => {
          setCounter(prevCounter => prevCounter - 1);
        }, 1000);

        return () => clearInterval(timer);
      }
    }, [counter]);

    
    
    

    const verifyotp = async()=>{
      try {
        const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "email": email,
    "otp": otp
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("https://api.jaishreeramhealthcareprivateltd.com/users/verify-otp", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.message) {
        toast.success(result.message, {
          autoClose: 3000,
        });
        // Save the token to localStorage
        localStorage.setItem("token", result.token);
        const token = localStorage.getItem("token");
        console.log("Token set in localStorage:", token);
        if (result.user_data === "Available") {
          navigate("/home");
        } else {
        navigate("/signup");
        }

        // Clear the OTP field
        setOtp("");
      } else {
        toast.error(result.message || "OTP verification failed", {
          autoClose: 3000,
        });
      }
    })
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <div style={{overflowX:"hidden"}}>
      <ToastContainer />
      <div className="login-container">
        <div className="login-left-pane">
          <div className="login-logo">
            <div className="login-logo1">
              <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            </div>
          </div>
          
          <h1 className='pt-3'>Welcome<br/>Jai Shree Ram Healthcare<br/>Pvt. Ltd.!</h1>
          <p className="login-description pt-3">Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.</p>
        </div>
        <div className="login-right-pane-centered">
          <h4 style={{textAlign:"center",paddingRight:"120px"}}>Enter OTP</h4>
          <div className="otp-input-container">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: 50, 
                height: 40, 
                margin: "10px", 
                borderRadius: "10px", 
                marginLeft: "0px",
                border: "2px solid #00A3FF",
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              }}
            />
          </div>
          <button
            className=""
            style={{
              backgroundColor: "#FF1493",
              width: "35%",
              padding: "10px",
              color: "white",
              fontSize: "17px",
              border: "none",
              borderRadius: "20px",
              alignSelf: "center",
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              display: "flex",          
              alignItems: "center",     
              justifyContent: "center", 
            }}
            onClick={verifyotp}
          >
          {loading ? (
            <BallTriangle
              height={50}               
              width={50}                
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              visible={true}
            />
          ) : (
            "Continue"
          )}
          </button>
    
          {counter > 0 ? (
            <p style={{ textAlign: "center", color: "red" }}>Resend OTP in {counter} seconds</p>
          ) : (
            <button
              className=""
              style={{
                background: "none",
                width: "35%",
                padding: "10px",
                fontSize: "17px",
                border: "none",
                borderRadius: "20px",
                color: "blue",
                textAlign: "center",
                alignSelf: "center"
              }}
              disabledd={!canResend}
              // onClick={otpSend}
            >
              Resend
            </button>
          )}
        </div>
      </div>
    </div>
    
    );
  }

  export default LoginOtp;

