// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Landing from './Compontes/Landing';
// import LoginScreen from './Compontes/LoginScreen';
// import LoginOtp from './Compontes/LoginOtp';
// import Home from './Compontes/MainCom/Home';
// import UserProfile from './Compontes/MainCom/UserProfile';
// import DroctotCat from './Compontes/MainCom/DroctotCat';
// import Hospitlas from './Compontes/MainCom/Hospitlas';
// import CategeroyCards from './Compontes/MainCom/CategeroyCards';
// import SignUp from './Compontes/SignUp';
// import Plans from './Compontes/MainCom/Plans';
// import UploadImage from './Compontes/MainCom/UploadImage';
// import VideoUpload from './Compontes/MainCom/VideoUpload';

// function App() {
//   // Example authentication check from Redux (or any state management)
//   const isAuth = !!localStorage.getItem("token");
//   console.log(localStorage.getItem("token"), isAuth, 'isAuth=====>');

//   // Component to protect routes
//   const ProtectedRoute = ({ element }) => {
//     return isAuth ? element : <Navigate to="/" replace />;
//   };

//   // Component to handle unauthenticated routes (like Login)
//   const AuthRoute = ({ element }) => {
//     return isAuth ? <Navigate to="/home" replace /> : element;
//   };

//   return (
//     <Routes>
//       {/* Public routes */}
//       <Route
//         path="/"
//         element={<Landing/>}
//       />
//       <Route path="/loginNumber" element={<LoginScreen />}  />
//       <Route path="/LoginOtp" element={<LoginOtp />}  />
//       <Route path="/signup" element={<SignUp />}  />

//       {/* Protected routes */}
//       <Route path="/home"  element={<Home/>} />
//       <Route path="/UserProfile"  element={<UserProfile />}  />
//       <Route path="/DroctotCat"  element={<DroctotCat />}  />
//       <Route path="/Hospitals"  element={<Hospitlas />} />
//       <Route path="/CategeroyCards"  element={<CategeroyCards />} />
//       <Route path="/plans"  element={<Plans />}  />
//       <Route path="/uploadImage"  element={<UploadImage />}/>
//       <Route path="/videoUpload"  element={<VideoUpload />}  />

//       {/* Catch all undefined routes */}
//       {/*<Route path="*" element={<Navigate to={isAuth ? "/home" : "/"} />} />*/}
//     </Routes>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './Compontes/Landing';
import LoginScreen from './Compontes/LoginScreen';
import LoginOtp from './Compontes/LoginOtp';
import Home from './Compontes/MainCom/Home';
import UserProfile from './Compontes/MainCom/UserProfile';
import DroctotCat from './Compontes/MainCom/DroctotCat';
import Hospitlas from './Compontes/MainCom/Hospitlas';
import CategeroyCards from './Compontes/MainCom/CategeroyCards';
import SignUp from './Compontes/SignUp';
import Plans from './Compontes/MainCom/Plans';
import UploadImage from './Compontes/MainCom/UploadImage';
import VideoUpload from './Compontes/MainCom/VideoUpload';

function App() {
  // Example authentication check from Redux (or any state management)
  const isAuth = !!localStorage.getItem("token");
  console.log(localStorage.getItem("token"), isAuth, 'isAuth=====>');

  // Component to protect routes
  const ProtectedRoute = ({ element }) => {
    return isAuth ? element : <Navigate to="/" replace />;
  };

  // Component to handle unauthenticated routes (like Login)
  const AuthRoute = ({ element }) => {
    return isAuth ? <Navigate to="/home" replace /> : element;
  };

  return (
    <Routes>
      {/* Public routes */}
      <Route
        path="/"
        element={isAuth ? <Navigate to="/home" replace /> : <Landing />}
      />
      <Route path="/loginNumber" element={<AuthRoute element={<LoginScreen />} />} />
      <Route path="/LoginOtp" element={<AuthRoute element={<LoginOtp />} />} />
      <Route path="/signup" element={<AuthRoute element={<SignUp />} />} />

      {/* Protected routes */}
      <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/UserProfile" element={<ProtectedRoute element={<UserProfile />} />} />
      <Route path="/DroctotCat" element={<ProtectedRoute element={<DroctotCat />} />} />
      <Route path="/Hospitlas" element={<ProtectedRoute element={<Hospitlas />} />} />
      <Route path="/CategeroyCards" element={<ProtectedRoute element={<CategeroyCards />} />} />
      <Route path="/plans" element={<ProtectedRoute element={<Plans />} />} />
      <Route path="/uploadImage" element={<ProtectedRoute element={<UploadImage />} />} />
      <Route path="/videoUpload" element={<ProtectedRoute element={<VideoUpload />} />} />

      {/* Catch all undefined routes */}
      <Route path="*" element={<Navigate to={isAuth ? "/home" : "/"} />} />
    </Routes>
  );
}

export default App;