import React, { useState } from 'react';
import jai from '../Img/jai.png';
import profile from '../Img/profile.png';

import { useNavigate, useNavigation } from 'react-router-dom';
import './home.css'
const UserProfile = () => {


  return (
    <div className="login-container">
      <div className="left-pane sticky-right  ">
        <div className="login-logo">
          <div className="login-logo1">
            <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
          </div>
        </div>

        <h3 className='pt-3 text-center'>Welcome<br />Jai Shree Ram Healthcare<br />Pvt. Ltd.!</h3>
        <p className="login-description pt-3">Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.</p>
      </div>
      <div className="right-pane pt-5">
        <div className='ms-3 '>
          <div className='d-flex'>
            <div className='ms-5 pro' style={{ height: 130, width: 130, border: "4px solid #FF1493", borderRadius: 100, backgroundColor: "#D9D9D9", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img src={profile} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />

            </div>
            <div className='align-content-center '>
              <button data-bs-toggle="modal" data-bs-target="#exampleModal" className='m-3' style={{ border: "none", background: "none", backgroundColor: "#FF1493", color: "white", fontSize: 17, padding: 8, borderRadius: "10px", paddingLeft: "20px", paddingRight: "20px" }}  >Edit Profile</button>
              <button className='m-3' style={{ border: "none", background: "none", backgroundColor: "#FF1493", color: "white", fontSize: 17, padding: 8, borderRadius: "10px" }} >Upload Photo</button>
            </div>
          </div>
        </div>
        <div className='row justify-content-center align-items-center d-flex mt-3 m-3 pb-2'>

          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>
          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>

          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>
          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>
          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>

          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>

          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>
          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>
          <div className='col-3 m-2' style={{ border: "1px solid red", height: 140 }}></div>


        </div>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header " style={{ border: "0" }}>
              <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Profile</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className='mx-auto' style={{ height: 130, width: 130, border: "4px solid #FF1493", borderRadius: 100, backgroundColor: "#D9D9D9", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <img src={profile} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />

              </div>
              <h6 className="pt-3"> Name :</h6>
              <input placeholder=" Name" style={{ width: "80%", borderRadius: 5, border: "1px solid gray", padding: 5 }} />
              <h6 className="pt-3"> Number:</h6>
              <input placeholder="Number" style={{ width: "80%", borderRadius: 5, border: "1px solid gray", padding: 5 }} type='number' />
            </div>
            <div class="mb-3 mx-auto mt-3 ">
              <button type="button" class="btn btn-primary ps-5 pe-5">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
