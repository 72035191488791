// import { data } from "jquery";
// import Navbar from "./Navbar";
// import './upload.css'
// import React, { useRef, useState } from 'react';
// import { toast, ToastContainer } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { BallTriangle } from "react-loader-spinner";
// import Footer from "../Footer";
// function UploadImage() {
//   const navigate = useNavigate();
//   const [imageChosen, setImageChosen] = useState(false);
//   const [videoChosen, setVideoChosen] = useState(false);
//   const [imageSrc, setImageSrc] = useState(null);
//   const [imageSrc1, setImageSrc1] = useState(null);
//   const [videoSrc, setVideoSrc] = useState(null);
//   const [url, setUrl] = useState();
//   const [category, setCategory] = useState();
//   const [size, setSize] = useState();
//   console.log(size, "size")
//   const [loading, setLoading] = useState(false);

//   const uploadPlan = async () => {
//     setLoading(true);
//     try {
//       const myHeaders = new Headers();
//       const token = localStorage.getItem("token");
//       const id = localStorage.getItem("id");
//       console.log(token)
//       myHeaders.append("Authorization", `Bearer ${token}`);
  
//       const formdata = new FormData();
//       formdata.append("category", category);
//       formdata.append("url", url);
//       formdata.append("size", size);
//       formdata.append("images", imageSrc1);
//       formdata.append("userId", id);
  
//       // Log FormData values
//       for (let pair of formdata.entries()) {
//         console.log(`${pair[0]}: ${pair[1]}`);
//       }
  
//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: formdata,
//         redirect: "follow",
//       };
  
//       const response = await fetch(
//         "https://api.jaishreeramhealthcareprivateltd.com/plans/add/plan",
//         requestOptions
//       );
  
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
  
//       const result = await response.json();
  
//       if (result.success) {
//         toast.success(result.message, {
//           autoClose: 3000,
//         });
//         console.log(result,"dsd asdsfdgs asdsgsdf")
//         navigate("/home");
//       } else {
//         toast.error(result.err, {
//           autoClose: 3000,
//         });
//       }
//     } catch (error) {
//       console.error("Error uploading the plan:", error);
//       toast.error(`Error uploading the plan: ${error.message}`, {
//         autoClose: 3000,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };
  
//  const handleImageChange = (event) => {
//     const file = event.target.files[0];

//     if (file) {
//       setImageSrc1(file)
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setImageSrc(e.target.result);
//       };
//       reader.readAsDataURL(file);
//       setImageChosen(true);
//     }
//   };

//   const handleVideoChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setVideoSrc(reader.result);
//         setVideoChosen(true);
//       };
//       reader.readAsDataURL(file);
//     }
//   };
//   return <div>
//   <ToastContainer/>
//     <section>
//       <Navbar />
//     </section>
//     <section>
//       <div className="container">
//         <div className="row pt-4 p-2">
//           <div className="col-lg-6 col-sm-12 col-md-12 mx-auto mb-5" style={{
//             boxShadow: '#C60000 0px 0px 0px 3px, #C60000 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset', borderRadius: 5, backgroundColor: "#f5f0f0"
//           }}
//           >
//             <div className="m-4">
//             <select  value={category} onChange={(ev) =>{ console.log(ev); setCategory(ev.target.value)}}  className="form-select" aria-label="Default select example">
//             <option value="" className="data">Select category</option>
//             <option value="In spotlight" className="data">In spotlight</option>
//             <option value="Top plans" className="data">Top plans</option>
//             <option value="Best offers" className="data">Best offers</option>
//           </select>
          
//           <select onChange={(ev) => setSize(ev.target.value)} value={size} className="form-select mt-4" aria-label="Default select example">
//             <option value="" className="data">Select size</option>
//             <option value="2X1" className="data">2X1</option>
//             <option value="4X1" className="data">4X1</option>
//             <option value="3X4" className="data">3X4</option>
//             <option value="1X1" className="data">1X1</option> 
//           </select>
          

//               <div className="pt-5">
//                 <h5 style={{ color: "#FF0596" }}>URL</h5>
//                 <input onChange={(e) => setUrl(e.target.value)} value={url} className="w-100 p-1" style={{ border: "1px solid #C71585 ", borderRadius: 5, outline: "none" }} placeholder="Paste your url here" />
//               </div>

//               <div>
//                 <div className="pt-4 d-flex justify-content-between gap-4">
//                   <div className="col-6">
//                     <h5 style={{ color: "#FF0596" }}>Image file</h5>
//                     <p style={{ fontSize: 14, color: "gray", marginTop: -10 }}>Select an image to upload</p>
//                     {!imageChosen ? (
//                       <div className="p-5" style={{ border: "1px solid #C71585", borderRadius: 5, position: 'relative', textAlign: 'center' }}>
//                         <input
//                           type="file"
//                           accept="image/*"
//                           id="image-input"
//                           onChange={handleImageChange}
//                           style={{
//                             background: 'none',
//                             border: 'none',
//                             width: '100%',
//                             height: '100%',
//                             opacity: 0,
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             cursor: 'pointer'
//                           }}
//                         />
//                         <span style={{
//                           display: 'block',
//                           color: 'blue',
//                           fontSize: '1rem',
//                           position: 'absolute',
//                           top: '30%',
//                           left: '50%',
//                           transform: 'translate(-50%, -50%)',
//                           pointerEvents: 'none',
//                         }}>Choose a File</span>
//                         <p style={{ fontSize: '0.875rem', color: 'gray', marginTop: '30px' }}>or Drop here</p>
//                       </div>
//                     ) : (
//                       <div style={{ height: 190, border: "1px solid #C71585", backgroundSize: "cover", backgroundRepeat: "no-repeat", overflow: "hidden" }}>
//                         <img src={imageSrc} alt="Preview" style={{ width: '100%', height: '100%', borderRadius: 5, objectFit: "cover" }} />
//                       </div>
//                     )}
//                   </div>
//                   <div className="col-6">
//                     <h5 style={{ color: "#FF0596" }}>Video file</h5>
//                     <p style={{ fontSize: 14, color: "gray", marginTop: -10 }}>Select a video to upload</p>
//                     {!videoChosen ? (
//                       <div className="p-5" style={{ border: "1px solid #C71585", borderRadius: 5, position: 'relative', textAlign: 'center' }}>
//                         <input
//                           type="file"
//                           accept="video/*"
//                           id="video-input"
//                           // onChange={handleVideoChange}
//                           style={{
//                             background: 'none',
//                             border: 'none',
//                             width: '100%',
//                             height: '100%',
//                             opacity: 0,
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             cursor: 'pointer'
//                           }}
//                         />
//                         <span style={{
//                           display: 'block',
//                           color: 'blue',
//                           fontSize: '1rem',
//                           position: 'absolute',
//                           top: '30%',
//                           left: '50%',
//                           transform: 'translate(-50%, -50%)',
//                           pointerEvents: 'none',
//                         }}>Choose a Video</span>
//                         <p style={{ fontSize: '0.875rem', color: 'gray', marginTop: '30px' }}>or Drop here</p>
//                       </div>
//                     ) : (
//                       <div style={{ height: 300, border: "1px solid #C71585", backgroundSize: "cover" }}>
//                         <video src={videoSrc} controls style={{ width: '100%', borderRadius: 5, marginTop: 10 }} /></div>
//                     )}
//                   </div>
//                 </div>

//               </div>


//               <button
//                 className="w-100 p-1 mt-5 mb-5 " style={{ background: "#FF0596", border: "none", color: "white", fontSize: 21, borderRadius: 10 , display: "flex",          
//                   alignItems: "center",     
//                   justifyContent: "center", }} onClick={uploadPlan}
//               >
//                 {loading ? (
//                   <BallTriangle
//                     height={50}
//                     width={50}
//                     radius={5}
//                     color="#4fa94d"
//                     ariaLabel="ball-triangle-loading"
//                     visible={true}
//                   />
//                 ) : (
//                   "Upload"
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     <Footer/>
//   </div>;
// }

// export default UploadImage;

import { data } from "jquery";
import Navbar from "./Navbar";
import './upload.css';
import React, { useRef, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import Footer from "../Footer";

function UploadImage() {
  const navigate = useNavigate();
  const [imageChosen, setImageChosen] = useState(false);
  const [videoChosen, setVideoChosen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSrc1, setImageSrc1] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [url, setUrl] = useState();
  const [category, setCategory] = useState();
  const [size, setSize] = useState();
  const [loading, setLoading] = useState(false);

  const uploadPlan = async () => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formdata = new FormData();
      formdata.append("category", category);
      formdata.append("url", url);
      formdata.append("size", size);
      formdata.append("images", imageSrc1);
      formdata.append("userId", id);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.jaishreeramhealthcareprivateltd.com/plans/add/plan",
        requestOptions
      );

      const result = await response.json();

      if (result.success) {
        toast.success(result.message, { autoClose: 3000 });
        navigate("/home");
      } else {
        toast.error(result.err, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(`Error uploading the plan: ${error.message}`, { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageSrc1(file);
      const reader = new FileReader();
      reader.onload = (e) => setImageSrc(e.target.result);
      reader.readAsDataURL(file);
      setImageChosen(true);
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setVideoSrc(reader.result);
      reader.readAsDataURL(file);
      setVideoChosen(true);
    }
  };

  return (
    <div>
      <ToastContainer />
      <section>
        <Navbar />
      </section>
      <section>
        <div className="container">
          <div className="row pt-4 p-2">
            <div
              className="col-lg-6 col-sm-12 col-md-12 mx-auto mb-5"
              style={{
                boxShadow: '#C60000 0px 0px 0px 3px, #C60000 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
                borderRadius: 5,
                backgroundColor: "#f5f0f0"
              }}
            >
              <div className="m-4">
                <select
                  value={category}
                  onChange={(ev) => setCategory(ev.target.value)}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="" className="data">Select category</option>
                  <option value="In spotlight" className="data">In spotlight</option>
                  <option value="Top plans" className="data">Top plans</option>
                  <option value="Best offers" className="data">Best offers</option>
                </select>

                {/* Conditionally render size options based on category */}
                <select
                  onChange={(ev) => setSize(ev.target.value)}
                  value={size}
                  className="form-select mt-4"
                  aria-label="Default select example"
                >
                  <option value="" className="data">Select size</option>
                  {category === "In spotlight" ? (
                    <option value="4X1" className="data">4X1</option>
                  ) : (
                    <>
                      <option value="2X1" className="data">2X1</option>
                      <option value="4X1" className="data">4X1</option>
                      <option value="3X4" className="data">3X4</option>
                      <option value="1X1" className="data">1X1</option>
                    </>
                  )}
                </select>

                <div className="pt-5">
                  <h5 style={{ color: "#FF0596" }}>URL</h5>
                  <input
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                    className="w-100 p-1"
                    style={{ border: "1px solid #C71585 ", borderRadius: 5, outline: "none" }}
                    placeholder="Paste your url here"
                  />
                </div>

                <div className="pt-4 d-flex justify-content-between gap-4">
                  <div className="col-6">
                    <h5 style={{ color: "#FF0596" }}>Image file</h5>
                    <p style={{ fontSize: 14, color: "gray", marginTop: -10 }}>Select a Image to upload</p>
                    {!imageChosen ? (
                      <div className="p-5" style={{ border: "1px solid #C71585", borderRadius: 5, position: 'relative', textAlign: 'center' }}>
                        <input
                          type="file"
                          accept="image/*"
                          id="image-input"
                          onChange={handleImageChange}
                          style={{ background: 'none', border: 'none', width: '100%', height: '100%', opacity: 0, position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}
                        />
                        <span style={{ display: 'block', color: 'blue', fontSize: '1rem', position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}>Choose a File</span>
                        <p style={{ fontSize: '0.875rem', color: 'gray', marginTop: '30px' }}>or Drop here</p>
                      </div>
                    ) : (
                      <div style={{ height: 190, border: "1px solid #C71585", backgroundSize: "cover", overflow: "hidden" }}>
                        <img src={imageSrc} alt="Preview" style={{ width: '100%', height: '100%', borderRadius: 5, objectFit: "cover" }} />
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <h5 style={{ color: "#FF0596" }}>Video file</h5>
                    <p style={{ fontSize: 14, color: "gray", marginTop: -10 }}>Select a video to upload</p>
                    {!videoChosen ? (
                      <div className="p-5" style={{ border: "1px solid #C71585", borderRadius: 5, position: 'relative', textAlign: 'center' }}>
                        <input
                          type="file"
                          accept="video/*"
                          id="video-input"
                          style={{ background: 'none', border: 'none', width: '100%', height: '100%', opacity: 0, position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}
                        />
                        <span style={{ display: 'block', color: 'blue', fontSize: '1rem', position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}>Choose a Video</span>
                        <p style={{ fontSize: '0.875rem', color: 'gray', marginTop: '30px' }}>or Drop here</p>
                      </div>
                    ) : (
                      <div style={{ height: 300, border: "1px solid #C71585", backgroundSize: "cover" }}>
                        <video src={videoSrc} controls style={{ width: '100%', borderRadius: 5, marginTop: 10 }} />
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className="w-100 p-1 mt-5 mb-5"
                  style={{ background: "#FF0596", border: "none", color: "white", fontSize: 21, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", outline: "none" }}
                  onClick={uploadPlan}
                  disabled={loading}
                >
                  {loading ? (
                    <BallTriangle height={20} width={20} color="white" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default UploadImage;
