import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jai from '../Img/jai.png';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap Modal and Button components
import user from '../Img/user.png';
import upload from '../Img/upload.png';
import logout from '../Img/logout.png';
import sub from '../Img/saas.png';
import profile from '../Img/profile.png';
import { FaUser, FaTransgender, FaGem, FaLocationArrow, FaHandHoldingMedical, FaBriefcaseMedical } from "react-icons/fa";
import { IoCall } from "react-icons/io5";

function Navbar() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [bloodGroup, setBloodGroup] = useState("");

  // Function to show the modal
  const handleDownloadApp = () => {
    setShowModal(false);
  };

  // Function to hide the modal
  const handleCancel = () => {
    setShowModal(false);
  };

  // Function to handle the OK action (e.g., download the app)
  const handleLogoutClick = () => {
    // Here you can add the download logic, such as redirecting to a download page or triggering a download
    console.log('App download initiated');
    setShowModal(false);

    // Optionally, you can also log the user out here
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <div className='sticky-top' style={{}}>
      <nav className="navbar navbar-expand-lg " style={{ backgroundColor: '#FF0596' }}>
        <div className="container-fluid">
          <a className="navbar-brand" onClick={() => navigate('/home')} href="#">
            <img src={jai} alt="Logo" className="navbar-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              
              <a className="nav-link active" onClick={() => navigate("/plans")} aria-current="page" href="#">
                <img src={sub} alt="Logo" style={{ height: 30, width: 50, objectFit: "contain", filter: "brightness(2) invert(6)" }} />
                <p style={{ fontFamily: "cursive", color: "white" }}>Plans</p>
              </a>
              <a className="nav-link active "  onClick={() =>setShowModal(true)}  aria-current="page" href="#">
              <img src={upload} alt="Logo" style={{ height: 30, width: 50, objectFit: "contain", filter: "brightness(2) invert(6)" }} />
              <p style={{ fontFamily: "cursive", color: "white" }}>Upload</p>
            </a>
            <a className="nav-link active" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-current="page" href="#">
              <img src={user} alt="Logo" style={{ height: 30, width: 50, objectFit: "contain", filter: "brightness(2) invert(6)" }} />
              <p style={{ fontFamily: "cursive", color: "white" }}>Profile</p>
            </a>
            <a className="nav-link active" onClick={handleLogoutClick} aria-current="page" href="#">
              <img src={logout} alt="Logo" style={{ height: 30, width: 50, objectFit: "contain", filter: "brightness(2) invert(6)" }} />
              <p style={{ fontFamily: "cursive", color: "white" }}>LogOut</p>
            </a>
            </ul>
          </div>
        </div>
      </nav>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header " style={{ border: "0" }}>
            <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Profile</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className='mx-auto' style={{ height: 130, width: 130, border: "4px solid #FF1493", borderRadius: 100, backgroundColor: "#D9D9D9", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img src={profile} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />

            </div>
            <div>
              <form className="form" style={{ backgroundColor: "white" }} >
                <div className="input-container">
                  <FaUser size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Name"
                  // value={name}
                  // onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <IoCall size={24} />
                  <input
                    className="input"
                    type="number"
                    placeholder="Number"
                  // value={number}
                  // onChange={(e) => {
                  //   if (e.target.value.length <= 10) {
                  //     setNumber(e.target.value);
                  //   }
                  // }}
                  // maxLength={10} 
                  />
                </div>
                <div className="input-container">
                  <FaTransgender size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Gender"
                  // value={gender}
                  // onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <FaGem size={24} />
                  <input
                    className="input"
                    type="number"
                    placeholder="Age"
                  // value={age}
                  // onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <FaLocationArrow size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Address"
                  // value={address}
                  // onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <FaHandHoldingMedical size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Do you have Insurance?"
                  // value={insurance}
                  // onChange={(e) => setInsurance(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <FaHandHoldingMedical size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Which type of insurance?"
                  // value={insuranceType}
                  // onChange={(e) => setInsuranceType(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <FaHandHoldingMedical size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Which company insurance?"
                  // value={insuranceCompany}
                  // onChange={(e) => setInsuranceCompany(e.target.value)}
                  />
                </div>
                <label className="label">Blood Group</label>
                <div className="blood-group-container" >
                  {['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'].map((group, index) => (
                    <button
                      // key={group}
                      type="button"
                      className={`blood-group-button1 ${bloodGroup === group ? 'selected1' : ''}`}
                      // onClick={() => setBloodGroup(group)}
                      style={index < 4 ? { marginRight: "2%" } : {}}
                    >
                      {group}
                    </button>
                  ))}
                </div>
                <div className="input-container">
                  <FaBriefcaseMedical size={24} />
                  <input
                    className="input"
                    type="text"
                    placeholder="Profession"
                  // value={profession}
                  // onChange={(e) => setProfession(e.target.value)}
                  />
                </div>
                {/* <div className="input-container">
          <BiCategoryAlt size={24} />
          <input
            className="input"
            type="text"
            placeholder="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>*/}
                <button type="submit" className="button" style={{ backgroundColor: "#0539FF", justifyContent: "center", alignItems: "center", display: "flex" }}>
                  Save
                </button>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>

      {/* Modal for Download App Popup */}
      <Modal show={showModal} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download the App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Would you like to download the app to get the best experience?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDownloadApp}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
